// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-am-404-js": () => import("./../../../src/pages/am/404.js" /* webpackChunkName: "component---src-pages-am-404-js" */),
  "component---src-pages-am-affiliates-js": () => import("./../../../src/pages/am/affiliates.js" /* webpackChunkName: "component---src-pages-am-affiliates-js" */),
  "component---src-pages-am-app-js": () => import("./../../../src/pages/am/app.js" /* webpackChunkName: "component---src-pages-am-app-js" */),
  "component---src-pages-am-contact-js": () => import("./../../../src/pages/am/contact.js" /* webpackChunkName: "component---src-pages-am-contact-js" */),
  "component---src-pages-am-index-js": () => import("./../../../src/pages/am/index.js" /* webpackChunkName: "component---src-pages-am-index-js" */),
  "component---src-pages-am-license-js": () => import("./../../../src/pages/am/license.js" /* webpackChunkName: "component---src-pages-am-license-js" */),
  "component---src-pages-am-objects-js": () => import("./../../../src/pages/am/objects.js" /* webpackChunkName: "component---src-pages-am-objects-js" */),
  "component---src-pages-am-prices-js": () => import("./../../../src/pages/am/prices.js" /* webpackChunkName: "component---src-pages-am-prices-js" */),
  "component---src-pages-by-404-js": () => import("./../../../src/pages/by/404.js" /* webpackChunkName: "component---src-pages-by-404-js" */),
  "component---src-pages-by-affiliates-js": () => import("./../../../src/pages/by/affiliates.js" /* webpackChunkName: "component---src-pages-by-affiliates-js" */),
  "component---src-pages-by-affiliates-table-js": () => import("./../../../src/pages/by/affiliates-table.js" /* webpackChunkName: "component---src-pages-by-affiliates-table-js" */),
  "component---src-pages-by-affiliates-update-js": () => import("./../../../src/pages/by/affiliates-update.js" /* webpackChunkName: "component---src-pages-by-affiliates-update-js" */),
  "component---src-pages-by-app-js": () => import("./../../../src/pages/by/app.js" /* webpackChunkName: "component---src-pages-by-app-js" */),
  "component---src-pages-by-blog-js": () => import("./../../../src/pages/by/blog.js" /* webpackChunkName: "component---src-pages-by-blog-js" */),
  "component---src-pages-by-contact-js": () => import("./../../../src/pages/by/contact.js" /* webpackChunkName: "component---src-pages-by-contact-js" */),
  "component---src-pages-by-index-js": () => import("./../../../src/pages/by/index.js" /* webpackChunkName: "component---src-pages-by-index-js" */),
  "component---src-pages-by-license-js": () => import("./../../../src/pages/by/license.js" /* webpackChunkName: "component---src-pages-by-license-js" */),
  "component---src-pages-by-objects-js": () => import("./../../../src/pages/by/objects.js" /* webpackChunkName: "component---src-pages-by-objects-js" */),
  "component---src-pages-by-price-details-js": () => import("./../../../src/pages/by/price_details.js" /* webpackChunkName: "component---src-pages-by-price-details-js" */),
  "component---src-pages-by-prices-js": () => import("./../../../src/pages/by/prices.js" /* webpackChunkName: "component---src-pages-by-prices-js" */),
  "component---src-pages-by-skidki-js": () => import("./../../../src/pages/by/skidki.js" /* webpackChunkName: "component---src-pages-by-skidki-js" */),
  "component---src-pages-by-user-agreements-js": () => import("./../../../src/pages/by/user-agreements.js" /* webpackChunkName: "component---src-pages-by-user-agreements-js" */),
  "component---src-pages-cy-en-404-js": () => import("./../../../src/pages/cy_en/404.js" /* webpackChunkName: "component---src-pages-cy-en-404-js" */),
  "component---src-pages-cy-en-affiliates-js": () => import("./../../../src/pages/cy_en/affiliates.js" /* webpackChunkName: "component---src-pages-cy-en-affiliates-js" */),
  "component---src-pages-cy-en-app-js": () => import("./../../../src/pages/cy_en/app.js" /* webpackChunkName: "component---src-pages-cy-en-app-js" */),
  "component---src-pages-cy-en-blog-js": () => import("./../../../src/pages/cy_en/blog.js" /* webpackChunkName: "component---src-pages-cy-en-blog-js" */),
  "component---src-pages-cy-en-contact-js": () => import("./../../../src/pages/cy_en/contact.js" /* webpackChunkName: "component---src-pages-cy-en-contact-js" */),
  "component---src-pages-cy-en-faq-partner-js": () => import("./../../../src/pages/cy_en/faq_partner.js" /* webpackChunkName: "component---src-pages-cy-en-faq-partner-js" */),
  "component---src-pages-cy-en-index-js": () => import("./../../../src/pages/cy_en/index.js" /* webpackChunkName: "component---src-pages-cy-en-index-js" */),
  "component---src-pages-cy-en-license-js": () => import("./../../../src/pages/cy_en/license.js" /* webpackChunkName: "component---src-pages-cy-en-license-js" */),
  "component---src-pages-cy-en-objects-js": () => import("./../../../src/pages/cy_en/objects.js" /* webpackChunkName: "component---src-pages-cy-en-objects-js" */),
  "component---src-pages-cy-en-prices-js": () => import("./../../../src/pages/cy_en/prices.js" /* webpackChunkName: "component---src-pages-cy-en-prices-js" */),
  "component---src-pages-en-am-404-js": () => import("./../../../src/pages/en_am/404.js" /* webpackChunkName: "component---src-pages-en-am-404-js" */),
  "component---src-pages-en-am-affiliates-js": () => import("./../../../src/pages/en_am/affiliates.js" /* webpackChunkName: "component---src-pages-en-am-affiliates-js" */),
  "component---src-pages-en-am-app-js": () => import("./../../../src/pages/en_am/app.js" /* webpackChunkName: "component---src-pages-en-am-app-js" */),
  "component---src-pages-en-am-contact-js": () => import("./../../../src/pages/en_am/contact.js" /* webpackChunkName: "component---src-pages-en-am-contact-js" */),
  "component---src-pages-en-am-index-js": () => import("./../../../src/pages/en_am/index.js" /* webpackChunkName: "component---src-pages-en-am-index-js" */),
  "component---src-pages-en-am-license-js": () => import("./../../../src/pages/en_am/license.js" /* webpackChunkName: "component---src-pages-en-am-license-js" */),
  "component---src-pages-en-am-objects-js": () => import("./../../../src/pages/en_am/objects.js" /* webpackChunkName: "component---src-pages-en-am-objects-js" */),
  "component---src-pages-en-am-prices-js": () => import("./../../../src/pages/en_am/prices.js" /* webpackChunkName: "component---src-pages-en-am-prices-js" */),
  "component---src-pages-eu-en-404-js": () => import("./../../../src/pages/eu_en/404.js" /* webpackChunkName: "component---src-pages-eu-en-404-js" */),
  "component---src-pages-eu-en-index-js": () => import("./../../../src/pages/eu_en/index.js" /* webpackChunkName: "component---src-pages-eu-en-index-js" */),
  "component---src-pages-faq-template-js": () => import("./../../../src/pages/faq-template.js" /* webpackChunkName: "component---src-pages-faq-template-js" */),
  "component---src-pages-lt-en-404-js": () => import("./../../../src/pages/lt_en/404.js" /* webpackChunkName: "component---src-pages-lt-en-404-js" */),
  "component---src-pages-lt-en-affiliates-js": () => import("./../../../src/pages/lt_en/affiliates.js" /* webpackChunkName: "component---src-pages-lt-en-affiliates-js" */),
  "component---src-pages-lt-en-app-js": () => import("./../../../src/pages/lt_en/app.js" /* webpackChunkName: "component---src-pages-lt-en-app-js" */),
  "component---src-pages-lt-en-blog-js": () => import("./../../../src/pages/lt_en/blog.js" /* webpackChunkName: "component---src-pages-lt-en-blog-js" */),
  "component---src-pages-lt-en-contact-js": () => import("./../../../src/pages/lt_en/contact.js" /* webpackChunkName: "component---src-pages-lt-en-contact-js" */),
  "component---src-pages-lt-en-faq-partner-js": () => import("./../../../src/pages/lt_en/faq_partner.js" /* webpackChunkName: "component---src-pages-lt-en-faq-partner-js" */),
  "component---src-pages-lt-en-index-js": () => import("./../../../src/pages/lt_en/index.js" /* webpackChunkName: "component---src-pages-lt-en-index-js" */),
  "component---src-pages-lt-en-license-js": () => import("./../../../src/pages/lt_en/license.js" /* webpackChunkName: "component---src-pages-lt-en-license-js" */),
  "component---src-pages-lt-en-objects-js": () => import("./../../../src/pages/lt_en/objects.js" /* webpackChunkName: "component---src-pages-lt-en-objects-js" */),
  "component---src-pages-lt-en-prices-js": () => import("./../../../src/pages/lt_en/prices.js" /* webpackChunkName: "component---src-pages-lt-en-prices-js" */),
  "component---src-pages-lt-lt-404-js": () => import("./../../../src/pages/lt_lt/404.js" /* webpackChunkName: "component---src-pages-lt-lt-404-js" */),
  "component---src-pages-lt-lt-affiliates-js": () => import("./../../../src/pages/lt_lt/affiliates.js" /* webpackChunkName: "component---src-pages-lt-lt-affiliates-js" */),
  "component---src-pages-lt-lt-app-js": () => import("./../../../src/pages/lt_lt/app.js" /* webpackChunkName: "component---src-pages-lt-lt-app-js" */),
  "component---src-pages-lt-lt-contact-js": () => import("./../../../src/pages/lt_lt/contact.js" /* webpackChunkName: "component---src-pages-lt-lt-contact-js" */),
  "component---src-pages-lt-lt-index-js": () => import("./../../../src/pages/lt_lt/index.js" /* webpackChunkName: "component---src-pages-lt-lt-index-js" */),
  "component---src-pages-lt-lt-license-js": () => import("./../../../src/pages/lt_lt/license.js" /* webpackChunkName: "component---src-pages-lt-lt-license-js" */),
  "component---src-pages-lt-lt-objects-js": () => import("./../../../src/pages/lt_lt/objects.js" /* webpackChunkName: "component---src-pages-lt-lt-objects-js" */),
  "component---src-pages-lt-lt-prices-js": () => import("./../../../src/pages/lt_lt/prices.js" /* webpackChunkName: "component---src-pages-lt-lt-prices-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-ru-404-js": () => import("./../../../src/pages/ru/404.js" /* webpackChunkName: "component---src-pages-ru-404-js" */),
  "component---src-pages-ru-affiliates-js": () => import("./../../../src/pages/ru/affiliates.js" /* webpackChunkName: "component---src-pages-ru-affiliates-js" */),
  "component---src-pages-ru-app-js": () => import("./../../../src/pages/ru/app.js" /* webpackChunkName: "component---src-pages-ru-app-js" */),
  "component---src-pages-ru-contact-js": () => import("./../../../src/pages/ru/contact.js" /* webpackChunkName: "component---src-pages-ru-contact-js" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages/ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-ru-license-js": () => import("./../../../src/pages/ru/license.js" /* webpackChunkName: "component---src-pages-ru-license-js" */),
  "component---src-pages-ru-objects-js": () => import("./../../../src/pages/ru/objects.js" /* webpackChunkName: "component---src-pages-ru-objects-js" */),
  "component---src-pages-ru-prices-js": () => import("./../../../src/pages/ru/prices.js" /* webpackChunkName: "component---src-pages-ru-prices-js" */),
  "component---src-pages-su-ru-404-js": () => import("./../../../src/pages/su_ru/404.js" /* webpackChunkName: "component---src-pages-su-ru-404-js" */),
  "component---src-pages-su-ru-index-js": () => import("./../../../src/pages/su_ru/index.js" /* webpackChunkName: "component---src-pages-su-ru-index-js" */),
  "component---src-pages-ua-404-js": () => import("./../../../src/pages/ua/404.js" /* webpackChunkName: "component---src-pages-ua-404-js" */),
  "component---src-pages-ua-affiliates-js": () => import("./../../../src/pages/ua/affiliates.js" /* webpackChunkName: "component---src-pages-ua-affiliates-js" */),
  "component---src-pages-ua-contact-js": () => import("./../../../src/pages/ua/contact.js" /* webpackChunkName: "component---src-pages-ua-contact-js" */),
  "component---src-pages-ua-index-js": () => import("./../../../src/pages/ua/index.js" /* webpackChunkName: "component---src-pages-ua-index-js" */),
  "component---src-pages-ua-license-js": () => import("./../../../src/pages/ua/license.js" /* webpackChunkName: "component---src-pages-ua-license-js" */),
  "component---src-pages-ua-objects-js": () => import("./../../../src/pages/ua/objects.js" /* webpackChunkName: "component---src-pages-ua-objects-js" */),
  "component---src-pages-ua-prices-js": () => import("./../../../src/pages/ua/prices.js" /* webpackChunkName: "component---src-pages-ua-prices-js" */),
  "component---src-templates-affiliates-update-js": () => import("./../../../src/templates/affiliates-update.js" /* webpackChunkName: "component---src-templates-affiliates-update-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-faq-template-js": () => import("./../../../src/templates/faq-template.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-individual-license-js": () => import("./../../../src/templates/individual_license.js" /* webpackChunkName: "component---src-templates-individual-license-js" */),
  "component---src-templates-license-js": () => import("./../../../src/templates/license.js" /* webpackChunkName: "component---src-templates-license-js" */),
  "component---src-templates-member-policy-personal-data-processing-js": () => import("./../../../src/templates/member_policy_personal_data_processing.js" /* webpackChunkName: "component---src-templates-member-policy-personal-data-processing-js" */),
  "component---src-templates-member-recurring-payment-policy-js": () => import("./../../../src/templates/member_recurring_payment_policy.js" /* webpackChunkName: "component---src-templates-member-recurring-payment-policy-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-price-js": () => import("./../../../src/templates/price.js" /* webpackChunkName: "component---src-templates-price-js" */),
  "component---src-templates-rule-js": () => import("./../../../src/templates/rule.js" /* webpackChunkName: "component---src-templates-rule-js" */),
  "component---src-utils-page-redirect-redirect-page-js": () => import("./../../../src/utils/page-redirect/redirect-page.js" /* webpackChunkName: "component---src-utils-page-redirect-redirect-page-js" */)
}

